$font-default: 'Roboto', sans-serif;

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pdf-attache-set {
  border: dashed 2px #7c8e54;
  padding: 20px 38px;
  border-radius: 5px;
  @media (max-width: 767px) {
    padding: 25px;
    width: 100%;
    .pdf-button-container {
      margin: 0 auto;
    }
  }
  .upload-pdf {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #7d8185;
    margin-bottom: 25px;
  }
  .upload-text {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #162642;
    margin-bottom: 35px;
  }
}
.subtitle-slide-deck {
  animation: 1s fadeInOut linear forwards;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  font-family: $font-default, sans-serif !important;
}

.title-slide {
  animation: 1s fadeInOut linear forwards;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 22px;
  font-family: $font-default, sans-serif !important;
  margin-bottom: 1px !important;
  padding-top: 5px;
  font-weight: 700;

  &:hover,
  &:active {
    background-color: #162642 !important;
  }
}

.step-statement2 {
  animation: 1s fadeInOut linear forwards;
  font-size: 16px !important;
  font-family: $font-default, sans-serif !important;
  font-weight: bold;
}

.pdf-subtitle {
  animation: 1s fadeInOut linear forwards;
  padding-top: 0px !important;
  font-size: 15px;
  font-family: $font-default, sans-serif !important;
}

*:focus {
  outline: none !important;
}

.hide-button {
  display: none !important;
}

.pdf-upload-container {
  border: 2px dashed #7c8e54;
  box-sizing: border-box;
  border-radius: 9px;
  width: 400px;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.pdf-icon {
  float: left;
  padding: 0 !important;
  margin: 0 !important;
}

.file-name {
  font-size: 18px;
  font-family: $font-default, sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
  padding-top: 0 !important;
  text-align: left;
  margin-left: 15px;
  word-break: break-all;
}

.step-statement-selected {
  animation: 1s fadeInOut linear forwards;
  font-size: 20px;
  font-family: $font-default, sans-serif !important;
  font-weight: 700;
  color: #162642;
}

.remove {
  margin-top: 0px !important;
}

.remove-syndicate {
  margin-top: 15px !important;
}

.hide-no-thanks {
  display: none;
}

.remove-wrapper {
  .anticon-delete {
    padding-left: 10px;
  }
}
.choose-file {
  width: 100%;
  border: 1px solid #162642;
  border-radius: 5px;
}

form.input-form .done2 {
  float: right;
  border-radius: 5px !important;
  margin-right: 14px;
  width: 15%;
  font-size: 16px !important;
  font-family: $font-default, sans-serif !important;
  padding: 8px !important;
  border-radius: 3px solid #162642 !important;
  color: white;
  background-color: #162642;
  font-weight: 700;
  margin-top: 20px;
}

.input-file {
  display: none;
}
.pdf-button-container {
  animation: 1s fadeInOut linear forwards;
  color: white;
  padding: 5px 20px;
  width: 100%;
  font-family: 'Roboto';
  background-color: #162642 !important;
  border-color: #162642;
  border-radius: 8px;
  transition: none !important;

  &:hover,
  &:active,
  &:focus {
    transform: none !important;
    background-color: #162642 !important;
    cursor: pointer;
  }
}

.done2[disabled] {
  background-color: #f3f3f3 !important;
  border: 2px solid #f3f3f3 !important;
  border-radius: 5px;
  color: #dededf !important;
  font-weight: 400;
}

@media only screen and (max-width: 420px) {
  .interests3 {
    margin-left: 15px;
    font-size: 17px !important;
  }

  .file-remove {
    padding-top: 0px !important;
    font-size: 13px !important;
  }

  .subtitle-slide-deck {
    text-align: left;
    margin-left: 19px;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  form.input-form .done2 {
    width: 25%;
    padding: 6px !important;
  }

  .pdf-button-container {
    padding: 4px !important;
  }

  .title-slide {
    font-size: 18px !important;
  }

  .file-name {
    font-size: 13px !important;
  }

  .pdf-icon {
    width: 100px !important;
    height: 105px !important;
  }

  .pdf-subtitle {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 520px) {
  form.input-form .done2 {
    width: 25%;
    padding: 6px !important;
  }

  .subtitle-slide-deck {
    text-align: left;
    margin-left: 25px;
    font-size: 16px !important;
  }

  .pdf-icon {
    width: 100px !important;
    height: 100px !important;
  }

  .file-name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .pdf-button-container {
    .title-slide {
      font-size: 18px !important;
    }
  }

  .subtitle-slide-deck,
  .file-name,
  .step-statement2 {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 420px) {
  .pdf-button-container {
    .title-slide {
      font-size: 17px !important;
    }
  }

  .subtitle-slide-deck,
  .step-statement2 {
    font-size: 14px !important;
    width: 90% !important;
  }
}
