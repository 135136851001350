.tracking-monthly-bg {
  background: #f8f8f9;
  display: flex;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
}
.trackingBackbtn {
  margin: 30px 0px !important;
  margin-bottom: 40px !important;
  width: fit-content;
  a {
    border: none;
    color: #162642;
    border-radius: 5px;
    text-align: center;
    // background: #ffffff;
    font-weight: 400;
    font-family: Roboto;
    font-size: 15px;
    margin: 10px 0 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .trackingBackbtn {
    margin: 30px 20px !important;
    margin-bottom: 0 !important;
  }
}
.form-main-wrapper {
  h5 {
    font-size: 20px;
    line-height: 24px;
    color: #162642;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .custom-form {
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 60px 42px;
    margin-bottom: 50px;
    .tracking-monthly-select {
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #343a40;
      padding: 10px 15px;
      background: transparent;
      width: 100%;
    }
    .tracking-monthly-form-error {
      color: red;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      position: absolute;
      bottom: 0px;
    }
    .form-group {
      margin-bottom: 32px !important;
      label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #3a3a3a;
      }
      .tracking-dollar {
        position: relative;
        &::before {
          content: '$';
          position: absolute;
          top: 47%;
          transform: translateY(-50%);
          left: 10px;
          bottom: 0;
          right: 0;
          width: 10px;
        }
      }
      .tracking-dolla-input {
        padding: 6px 12px 6px 24px;
      }
    }
    label {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: #3a3a3a;
    }
    .btn-submit {
      background: #162642;
      border-radius: 5px;
      font-size: 22px;
      line-height: 52px;
      color: #ffffff;
      width: 340px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .disable {
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: 768px) {
    h5 {
      justify-content: center;
    }
    .custom-form {
      background: transparent;
      box-shadow: none;
      padding: 34px 24px;
      margin-bottom: 100px !important;
      text-align: left;
      .form-group {
        margin-bottom: 16px;
      }
    }
  }
}
.profile-header-sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}
.profile-footer-sticky {
  position: sticky;
  bottom: 0;
}
